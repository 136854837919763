import {OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IonContent} from '@ionic/angular';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AppComponent} from '../app.component';
import {Subscription} from 'rxjs';

/**
 * This class is a temporary fix for swipe back iOS bug https://github.com/ionic-team/ionic/issues/19644
 */
export class PageEnter implements OnInit, OnDestroy {

  @ViewChild(IonContent)
  ionContentElement: IonContent;

  private pageRouter: Router;

  private routerSubscription: Subscription;

  private pageUrl: string;
  private scrollPosition: number = null;
  private PAGE_ANIMATION_TIME: number = 750;

  constructor() {
    this.pageRouter = AppComponent.INJECTOR.get(Router);
  }

  ngOnInit(): void {
    this.pageUrl = this.pageRouter.url;
    this.routerSubscription = this.pageRouter.events
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url === this.pageUrl) {
            setTimeout(() => this.triggerScrollDownUp(), this.PAGE_ANIMATION_TIME);
          }
        } else if (event instanceof NavigationStart && this.scrollPosition === null) {
          this.triggerSetScrollPosition();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private triggerSetScrollPosition() {
    this.ionContentElement.getScrollElement()
      .then(async scroll => {
        this.scrollPosition = scroll.scrollTop;
      });
  }

  private async triggerScrollDownUp() {
      // Scrolling 1px up and 1px down prevents ios-page-jumping bug on horizontal scroll elements
      this.ionContentElement.scrollByPoint(0, 1, 0);
      this.ionContentElement.scrollByPoint(0, -1, 0);
      this.scrollPosition = null;
  }
}
