import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SectionItem } from '../../constants/interfaces';
import { IonVirtualScroll } from '@ionic/angular';
import {PageEnter} from '../../pages/PageEnter';

export class ScreenSectionDisplay extends PageEnter implements OnDestroy {

  sectionSubscription: Subscription;

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.sectionSubscription.unsubscribe();
  }

  trackItem(index: number, item: SectionItem) {
    return item.id;
  }

  getVirtualScrollItemHeight(item: SectionItem, index: number) {

    if (item.hidden) return 0;
    if (!! item.height) return item.height;

    return 100;

  }

  showMore(showMoreItem: SectionItem, items: SectionItem[], virtualScroll: IonVirtualScroll) {

    // Find the first item that is hidden in this set
    const firstHiddenItemIndex = showMoreItem.items.findIndex((itm) => itm.hidden);
    const numItemsToShow = showMoreItem.section.max_rows;
    const itemsToShow = showMoreItem.items.slice(firstHiddenItemIndex, firstHiddenItemIndex+numItemsToShow);

    const firstHiddenItem = showMoreItem.items[firstHiddenItemIndex];
    const itemIndexInTotalList = items.findIndex((itm) => itm.id === firstHiddenItem.id);

    itemsToShow.forEach((itm) => {
      itm.hidden = false;
    });

    if (!!virtualScroll) {
      virtualScroll.checkRange(itemIndexInTotalList, numItemsToShow);
    }
  }
}
