import {Component, Input} from '@angular/core';
import {Offer} from '../../constants/interfaces';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent {

  @Input()
  coupon: Offer;

}
