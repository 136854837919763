import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {CouponComponent} from './coupon.component';
import {ImageSizeModule} from '../../pipes/image-size/image-size.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ImageSizeModule
  ],
  declarations: [CouponComponent],
  exports: [CouponComponent]
})
export class CouponModule {
}
